import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { HourEntity } from "../types/Hours";
import { AuthContext } from "../App";

interface HourRowInterface {
    hour: HourEntity;
    handleDeleteHour: (id: number) => void;
}

export default function HourRow({ hour, handleDeleteHour }: HourRowInterface) {
    const [deleting, setDeleting] = useState(false);
    const [isAttested, setIsAttested] = useState(hour.attested);
    const { state } = useContext(AuthContext);

    const toggleAttest = () => {
        fetch(process.env.REACT_APP_API_URI + `hours/attest/${hour.id}.json`, {
            method: "PATCH",
            body: JSON.stringify({ attested: !isAttested }),
            headers: {
                Authorization: state.user?.token ?? "",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    setIsAttested(res.hour.attested);
                }
            });
    };

    const renderedDelete = deleting ? (
        <button className="btn btn-danger btn-sm" onClick={() => handleDeleteHour(hour.id)}>
            Slett
        </button>
    ) : (
        <FontAwesomeIcon
            icon={faTimesCircle}
            className="text-danger cursor-pointer"
            onClick={() => setDeleting(true)}
        />
    );

    return (
        <div className={"d-flex align-items-center p-2" + (isAttested ? " bg-success text-light border-bottom" : "")}>
            <div className="flex-grow-1 me-1" onClick={toggleAttest}>
                {hour.description}
            </div>
            <div style={{ whiteSpace: "nowrap" }}>
                {hour.amount}t {renderedDelete}
            </div>
        </div>
    );
}
