import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../App";
import { AuthActionType } from "../Auth/AuthReducer";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FlashMessage from "../components/FlashMessage";

export default function Login() {
    const { state, dispatch } = useContext(AuthContext);
    const [data, setData] = useState({
        errorMessage: "",
        isLoading: false,
    });
    const { client_id, redirect_uri } = state;

    useEffect(() => {
        // After requesting GitHub access, GitHub redirect back to your app with a code parameter
        const url = window.location.href;
        const hasCode = url.includes("?code=");

        // If GitHub API returns the code parameter
        if (hasCode) {
            const newUrl = url.split("?code=");
            window.history.pushState({}, "", newUrl[0]);
            setData((prevState) => ({ ...prevState, isLoading: true }));

            const requestData = {
                code: newUrl[1],
            };

            const proxy_url = state.proxy_url || "";

            // Use code parameter and other parameters to make POST request to proxy server
            fetch(proxy_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                    setData({
                        isLoading: false,
                        errorMessage: "Sorry! Login failed",
                    });
                })
                .then((data) => {
                    dispatch({
                        type: AuthActionType.LOGIN,
                        payload: {
                            user: data.user,
                            isLoggedIn: true,
                        },
                    });
                })
                .catch((error) => {
                    setData({
                        isLoading: false,
                        errorMessage: "Sorry! Login failed",
                    });
                });
        }
    }, [state, dispatch]);

    if (state.isLoggedIn) {
        return <Navigate replace to="/" />;
    }

    return (
        <div className="mt-4 text-center">
            <FontAwesomeIcon icon={faGithub} size={"4x"} />
            <h2 className="mt-3">Login with Github</h2>
            <a
                className="btn btn-dark mt-3"
                href={process.env.REACT_APP_GITHUB_AUTH_URL + `client_id=${client_id}&redirect_uri=${redirect_uri}`}
                onClick={() => setData((prevState) => ({ ...prevState, errorMessage: "" }))}
            >
                <FontAwesomeIcon icon={faGithub} /> Login
            </a>
            {data.errorMessage && <FlashMessage message="Error when authenticating." variant="danger" />}
        </div>
    );
}
