import React, { createContext, useReducer } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { AuthContextInterface, initialState, reducer } from "./Auth/AuthReducer";

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <>
            <header className="text-center p-1 p-md-2">
                <img src={"./img/logo-white-smaller.webp"} alt="" />
            </header>
            <AuthContext.Provider value={{ state, dispatch }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </BrowserRouter>
            </AuthContext.Provider>
        </>
    );
}

export default App;
