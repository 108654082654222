import { Dispatch } from "react";

interface UserInterface {
    id: number;
    githubId: number;
    name: string | null;
    email: string | null;
    token: string;
    created: string;
    modified: string | null;
}

export interface AuthStateContextInterface {
    isLoggedIn: boolean;
    user: UserInterface | null;
    client_id: string | undefined;
    redirect_uri: string | undefined;
    client_secret: string | undefined;
    proxy_url: string | undefined;
}

export interface AuthContextInterface {
    state: AuthStateContextInterface;
    dispatch: Dispatch<AuthReducerInterface>;
}

export const initialState: AuthStateContextInterface = {
    isLoggedIn:
        JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_PREFIX + "isLoggedIn") ?? "false") || false,
    user: JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_PREFIX + "user") ?? "{}") || null,
    client_id: process.env.REACT_APP_GITHUB_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_GITHUB_REDIRECT_URI,
    client_secret: process.env.REACT_APP_GITHUB_CLIENT_SECRET,
    proxy_url: process.env.REACT_APP_API_URI + "users/login.json",
};

export enum AuthActionType {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
}

interface AuthReducerInterface {
    type: AuthActionType;
    payload: any;
}

export const reducer = (state: AuthStateContextInterface, action: AuthReducerInterface) => {
    switch (action.type) {
        case "LOGIN": {
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_PREFIX + "isLoggedIn",
                JSON.stringify(action.payload.isLoggedIn)
            );
            localStorage.setItem(
                process.env.REACT_APP_LOCAL_STORAGE_PREFIX + "user",
                JSON.stringify(action.payload.user)
            );
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                user: action.payload.user,
            };
        }
        case "LOGOUT": {
            const storageKeys = Object.keys(localStorage);
            const appKeys = storageKeys.filter((key) =>
                key.startsWith(process.env.REACT_APP_LOCAL_STORAGE_PREFIX ?? "______")
            );
            appKeys.forEach((key) => localStorage.removeItem(key));
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        }
        default:
            return state;
    }
};
