import React, { useContext, useEffect, useState } from "react";
import DatePicker from "../components/DatePicker";
import HourForm from "../components/HourForm";
import Overview from "../components/Overview";
import { HourEntity } from "../types/Hours";
import { addDays } from "date-fns";
import { AuthContext } from "../App";
import format from "date-fns/format";
import { nb } from "date-fns/locale";
import { Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AuthActionType } from "../Auth/AuthReducer";
import { faSignOut } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Home() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [hours, setHours] = useState<HourEntity[]>([]);

    const { state, dispatch } = useContext(AuthContext);

    useEffect(() => {
        if (!state.isLoggedIn) {
            return;
        }
        fetch(process.env.REACT_APP_API_URI + "hours/index.json", {
            method: "POST",
            body: JSON.stringify({ date: format(currentDate, "yyyy-MM-dd", { locale: nb }) }),
            headers: {
                Authorization: state.user?.token ?? "",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((response) => setHours(response.hours));
    }, [currentDate, state.user, state.isLoggedIn]);

    if (!state.isLoggedIn) {
        return <Navigate replace to="/login" />;
    }

    const handleAddHours = (data: HourEntity) => {
        setHours((prevState) => prevState.concat(data));
    };

    const handleDeleteHour = (id: number) => {
        fetch(process.env.REACT_APP_API_URI + "hours/" + id + ".json", {
            method: "DELETE",
            headers: {
                Authorization: state.user?.token ?? "",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    setHours((prevState) => prevState.filter((hour) => hour.id !== id));
                }
            });
    };

    const handleDateChange = (offset: number) => {
        setCurrentDate((prevState) => addDays(prevState, offset));
    };

    const logout = () => {
        dispatch({
            type: AuthActionType.LOGOUT,
            payload: {},
        });
    };

    return (
        <>
            <main>
                <div className="container-fluid mt-2">
                    <DatePicker date={currentDate} handleDateChange={handleDateChange} />
                    <HourForm handleAddHours={handleAddHours} date={currentDate} />
                    <hr />
                    <Overview hours={hours} handleDeleteHour={handleDeleteHour} />
                    <Button variant="dark" onClick={() => logout()} size="sm">
                        <FontAwesomeIcon icon={faSignOut} /> Logg ut
                    </Button>
                </div>
            </main>
        </>
    );
}
