import React from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/types";

export default function FlashMessage({ message, variant }: { message: string; variant: Variant }) {
    return (
        <Alert variant={variant} className="mt-2">
            {message}
        </Alert>
    );
}
