import React from "react";
import HourRow from "./HourRow";
import HourTotal from "./HourTotal";
import { HourEntity } from "../types/Hours";

interface OverviewInterface {
    hours: HourEntity[];
    handleDeleteHour: (id: number) => void;
}

export default function Overview({ hours, handleDeleteHour }: OverviewInterface) {
    const total = hours.reduce((tempTotal, hour) => tempTotal + hour.amount, 0);
    const renderedHours = hours.map((hour) => (
        <HourRow key={hour.id} hour={hour} handleDeleteHour={handleDeleteHour} />
    ));

    return (
        <div className="my-3">
            {renderedHours}
            <HourTotal total={total} />
        </div>
    );
}
