import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/pro-light-svg-icons";
import format from "date-fns/format";
import { nb } from "date-fns/locale";

interface DatePickerInterface {
    date: Date;
    handleDateChange: (dayOffset: number) => void;
}

export default function DatePicker({ date, handleDateChange }: DatePickerInterface) {
    const dayName = () => {
        const formatted = format(date, "EEEE", { locale: nb });

        return formatted.slice(0, 1).toUpperCase() + formatted.slice(1, formatted.length);
    };
    const dateFormatted = () => {
        return format(date, "d. MMMM", { locale: nb });
    };

    return (
        <div className="text-center">
            <div className="date-day">{dayName()}</div>
            <div>
                <FontAwesomeIcon
                    icon={faArrowCircleLeft}
                    size="2x"
                    className="cursor-pointer me-3 cursor-pointer"
                    onClick={() => handleDateChange(-1)}
                />{" "}
                <span className="date-date cursor-pointer">{dateFormatted()}</span>{" "}
                <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    size="2x"
                    className="cursor-pointer ms-3 cursor-pointer"
                    onClick={() => handleDateChange(1)}
                />
            </div>
        </div>
    );
}
