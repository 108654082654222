import React from "react";

export default function HourTotal({ total }: { total: number }) {
    let bgColor = "";
    const FULL_DAY = 7.5;
    if (total === 0) {
        bgColor = "bg-danger";
    } else if (total < FULL_DAY) {
        bgColor = "bg-warning";
    } else if (total === FULL_DAY) {
        bgColor = "bg-success";
    } else if (total > FULL_DAY) {
        bgColor = "bg-darkblue";
    }

    return (
        <div className={"d-flex align-items-center p-2 text-white " + bgColor}>
            <div className="flex-grow-1">Totalt</div>
            <div>{total}t</div>
        </div>
    );
}
