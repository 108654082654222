import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, FormControl, InputGroup } from "react-bootstrap";
import { HourEntity, HoursAddRequestDataInterface } from "../types/Hours";
import format from "date-fns/format";
import { AuthContext } from "../App";
import FlashMessage from "./FlashMessage";
import { nb } from "date-fns/locale";

interface HourFormInterface {
    handleAddHours: (data: HourEntity) => void;
    date: Date;
}

export default function HourForm({ handleAddHours, date }: HourFormInterface) {
    const INCREMENT = 0.5;
    const FULL_DAY = 7.5;

    const { state } = useContext(AuthContext);

    const [data, setData] = useState<HoursAddRequestDataInterface>({
        description: "",
        amount: 0.0,
        date: format(date, "yyyy-MM-dd", { locale: nb }),
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validationErrors, setValidationErrors] = useState({
        description: "",
        amount: "",
        date: "",
    });

    useEffect(() => {
        setData((prevState) => ({
            ...prevState,
            date: format(date, "yyyy-MM-dd", { locale: nb }),
        }));
    }, [date]);

    const renderedDropdownOptions = [];
    for (let i = 0, hour = INCREMENT; i < FULL_DAY * 2; i++, hour += INCREMENT) {
        renderedDropdownOptions.push(
            <Dropdown.Item key={hour} variant="outline-success" id="amount-dropdown" onClick={() => setAmount(hour)}>
                {hour}
            </Dropdown.Item>
        );
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        console.log(date);
        console.log(format(date, "yyyy-MM-dd", { locale: nb }));
        fetch(process.env.REACT_APP_API_URI + "hours/add.json", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: state.user?.token ?? "",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    handleAddHours(data.hour);
                    setData((prevState) => ({
                        ...prevState,
                        description: "",
                        amount: 0,
                    }));
                    setErrorMessage(null);
                } else {
                    setErrorMessage(data?.message ?? "Ukjent feil");
                    if (data.errors) {
                        setValidationErrors(data?.errors);
                    }
                }
            });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        let newValue: any;
        if (name === "amount") {
            newValue = parseFloat(value);
            newValue = isNaN(newValue) ? 0 : newValue;
        } else {
            newValue = value;
        }
        setData((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
        setValidationErrors((prevState) => ({
            ...prevState,
            [name]: null,
        }));
    };

    const setAmount = (amount: number) => {
        setData((prevState) => ({
            ...prevState,
            amount: Math.max(0, Math.min(amount, 24)),
        }));
        setValidationErrors((prevState) => ({
            ...prevState,
        }));
    };

    return (
        <Form onSubmit={handleSubmit}>
            {errorMessage && <FlashMessage message={errorMessage} variant="danger" />}
            <Form.Group className="mb-3">
                <Form.Label>Beskrivelse</Form.Label>
                <Form.Control
                    isInvalid={validationErrors && validationErrors.description?.length > 0}
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Beskrivelse"
                    value={data.description}
                    onChange={handleChange}
                />
                {validationErrors && validationErrors.description?.length > 0 && (
                    <FormControl.Feedback type="invalid">{validationErrors.description[0]}</FormControl.Feedback>
                )}
            </Form.Group>
            <Form.Label htmlFor="amount">Antall</Form.Label>
            <InputGroup className="mb-3">
                <InputGroup.Text
                    aria-label="Reduser antall"
                    className="btn btn-danger"
                    onClick={() => setAmount(data.amount - INCREMENT)}
                >
                    -
                </InputGroup.Text>
                <Form.Control
                    isInvalid={validationErrors && validationErrors.amount?.length > 0}
                    required={true}
                    placeholder="Antall"
                    aria-label="Antall"
                    type="number"
                    min={0}
                    max={24}
                    step={0.5}
                    id="amount"
                    name="amount"
                    value={data.amount}
                    onChange={handleChange}
                />
                <InputGroup.Text
                    aria-label="Øk antall"
                    className="btn btn-success"
                    onClick={() => setAmount(data.amount + INCREMENT)}
                >
                    +
                </InputGroup.Text>
                <InputGroup.Text
                    aria-label="Full dag"
                    className="btn btn-outline-success"
                    onClick={() => setAmount(FULL_DAY)}
                >
                    {FULL_DAY}
                </InputGroup.Text>
                <DropdownButton variant="outline-success" title="" align="end" aria-label="Velg antall">
                    {renderedDropdownOptions}
                </DropdownButton>
                {validationErrors && validationErrors.amount?.length > 0 && (
                    <FormControl.Feedback type="invalid">{validationErrors.amount[0]}</FormControl.Feedback>
                )}
            </InputGroup>
            <div className="d-grid">
                <Button variant="success" type="submit">
                    Legg til
                </Button>
            </div>
        </Form>
    );
}
